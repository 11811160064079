#site-header{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	padding: 28px $margin;
	width: 100%;
	@include transition(400ms);
	@extend .clearfix;
	@media screen and (max-width:$responsive){
		position: absolute;	
	}
	a{
		color: #FFF;
		@include transition(200ms);
		&:hover{
			opacity: .64;
		}
	}
	&.active{
		@media screen and (min-width:$responsive + 1px){
			background: $normal;
			padding: 16px $margin;
			a{
				color: #FFF;;
			}
		}
	}
	.logo{
		width: 144px;
		float: left;
		background: url("../../../images/common/logo.png") no-repeat;
		background-size: cover;
		@media screen and (max-width:$responsive ){
			width: 96px;
		}
		img{
			width: 100%;
			opacity: 0;
			@include transition(200ms);
		}
	}
	.gnavi{
		float: right;
		margin: 6px 0 0;
		@media screen and (max-width:$responsive ){
			$size: 40px;
			&:before{
				content: '';
				float: none;
				position: fixed;
				top: 15px;
				right: 15px;
				width: $size;
				height: $size;
				border-radius: 50%;
				background: $normal;
				border: $normal 1px solid;;
				z-index: 1000;
				cursor: pointer;
				@include transition(400ms);
			}
			&:after{
                font-weight: 900;
                font-family: "Font Awesome 5 Free";
                content: "\f0c9";
				position: fixed;
				top: 24px;
				right: 29px;
				z-index: 1001;
				color: #FFF;
				font-size: 13px;
				cursor: pointer;
			}
			&.active{
				&:before{
					border: #FFF 1px solid;
					transform: scale(1.04);
				}
				&:after{
					content: "\f00d";
					right: 30px;
				}
			}
		}
		> ul{
			@extend .clearfix;
			@media screen and (min-width:$responsive + 1px){
				display: block !important;
			}
			@media screen and (max-width:$responsive ){
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: $normal;
				@include flex();
				align-items: center;
				justify-content: center;
				text-align: center;
				padding: 40% 0;
				z-index: 500;
				&:after{
					display: none;
				}
			}
			> li{
				list-style: none;
				float: left;
				margin: 0 5.5vw 0 0;
				font-size: 15px;
				@media screen and (max-width:$responsive ){
					float: none;
					width: 100%;
					margin: 0;
				}
				&:last-child{
					margin: 0;
				}
				>a{
					font-weight: 600;
				}
			}
		}
	}
}