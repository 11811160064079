.site-works {
	.workslist{
		@include flex();
		@media screen and (max-width:$responsive){
			display: block;	
		}
		li{
			width: calc(50% - 16px);
			@include flex();
			align-items: flex-start;
			padding: 24px;
			margin-top: 32px;
			box-shadow: 0 0 24px rgba(#000,.1);
			border-radius: 4px;
			position: relative;
			overflow: hidden;
			@media screen and (max-width:$responsive){
				width: auto;
				display: block;
				&:after{
					content: '';
					display: block;
					clear: both;
				}
			}
			@media screen and (min-width:$responsive + 1px){
				&:nth-child(1),
				&:nth-child(2){
					margin-top: 0;
				}
			}
			figure{
				width: 120px;
				@media screen and (max-width:$responsive){
					width: 64px;
					float: left;
					margin: 0 20px 0 0;
				}
				img{
					width: 100%;
				}
			}
			dl{
				width: calc(100% - 120px);
				padding: 8px 0 0 32px;
				@media screen and (max-width:$responsive){
					width: auto;
					padding: 10px 0 0;
				}
				dt{
					font-size: 17px;
					font-weight: 700;
					@media screen and (max-width:$responsive){
						font-size: 15px;	
					}
				}
				dd.cate{
					font-weight: 700;
					color: $blue;
					font-size: 12px;
				}
				dd.description{
					margin: 12px 0 0;
					padding: 12px 0 0;
					border-top: $border 1px solid;
					font-size: 12px;
					@media screen and (max-width:$responsive){
						clear: both;	
					}
				}
			}
			a.view{
				position: absolute;
				top: 0;
				right: 0;
				background: $normal;
				color: #FFF;
				font-size: 12px;
				padding: 3px 24px;
				i{
					display: inline-block;
					margin: 0 0 0 8px;
					font-size: 10px;
					opacity: .64;
				}
			}
		}
	}
	h3.borders{
		margin-top: 80px;
		@media screen and (max-width:$responsive){
			margin-top: 32px;	
		}
	}
	.min-list{
		@extend .clearfix;
		li{
			@media screen and (min-width:$responsive + 1px){
				@include clum(3,32%,2%);
			}
			@media screen and (max-width:$responsive){
				margin: 0 0 16px;
				&:last-child{
					margin: 0;
				}
			}
			list-style: none;
			background: #FFF;
			border: $border 1px solid;
			dl{
				padding: 16px;
				dt{
					font-size: 13px;
					font-weight: 700;
				}
				dd{
					font-size: 12px;
				}
			}
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3){
				margin-top: 0;
			}
		}
	}
}