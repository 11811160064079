#addmap{
	iframe{
		filter: grayscale(100);
		vertical-align: bottom;
		height: 300px;
		@media screen and (max-width:$responsive){
			height: 180px;	
		}
	}
}

#site-footer{
	$mg: 64px;
	background: $normal;
	padding: 0 0 $mg;
	color: #FFF;
	@media screen and (max-width:$responsive){
		padding: 0 0 32px;	
	}
	a{
		color: #FFF;
	}
	.pagetop{
		background: rgba(#FFF,.12);
		text-align: center;
		margin: 0 0 $mg;
		@media screen and (max-width:$responsive){
			margin: 0 0 32px;	
		}
		a{
			display: block;
			padding: 12px 0;
		}
	}
	.wrap{
		padding: 0 $margin-L;
		@media screen and (max-width:$responsive){
			padding: 0 15px;	
		}
	}
	.fnav{
		text-align: center;
		@media screen and (max-width:$responsive){
			border: rgba(#FFF,.12) 1px solid;
			border-radius: 4px;
		}
		li{
			font-size: 11px;
			display: inline-block;
			@media screen and (max-width:$responsive){
				display: block;	
				text-align: left;
				border-bottom: rgba(#FFF,.12) 1px solid;
				&:last-child{
					border: none;
				}
				a{
					display: block;
					padding: 15px;
					position: relative;
					&:after{
						font-weight: 900;
						font-family: "Font Awesome 5 Free";
						content: "\f054";
						position: absolute;
						right: 15px;
						top: 50%;
						transform: translateY(-50%);
						opacity: .24;
					}
				}
			}
			@media screen and (min-width:$responsive + 1px){
				&:last-child{
					&:after{
						display: none;
					}
				}
				&:after{
					content: '|';
					display: inline-block;
					margin: 0 12px;
				}
			}
		}
	}
	.copy{
		text-align: center;
		font-size: 10px;
		display: block;
		margin: 32px 0 0;
		@media screen and (max-width:$responsive){
			font-size: 8px;	
		}
	}
}