#site-firstview{
	.flexer{
		width: 100vw;
		height: 100vh;
		min-height: 500px;
        overflow: hidden;
		position: relative;
		background: #000;
        .swiper-container{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
			opacity: 0;
			@include transition(800ms);
			&.swiper-container-initialized{
				opacity: 1;
			}
			.swiper-bg{
				width: 100vw;
				height: 100vh;
				background-size: cover;
				background-position: center;
			}
        }
		.swiper-slide-active .swiper-bg,
		.swiper-slide-duplicate-active .swiper-bg,
		.swiper-slide-prev .swiper-bg{
			animation: zoomUp 6.5s linear 0s 1 normal both;  
		}
		@keyframes zoomUp {
			0% {
				transform: scale(1.08);
			}
			100% {
				transform: scale(1);
			}
    	}
		.overbox{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include flex();
			align-items: center;
			justify-content: center;
			z-index: 10;
			color: #FFF;
			overflow: hidden;
			background: rgba($normal,.16);
			*{
				z-index: 2;
			}
			&:before{
				content: '';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				position: absolute;
				pointer-events: none;
				box-shadow: inset 0 0 24vw rgba(#000,.16);
				z-index: 1;
			}
			dl{
				text-align: center;
				font-weight: 700;
				line-height: 1.18;
				transform: translateY(-10%);
				dd{
					@include catch;
					letter-spacing: .2em;
					font-size: calc(52px + 22 * (100vw - 450px)/ 900);
					transform: translateX(.24em);
					opacity: 0;
					@include transition(800ms);
					@media screen and (max-width:$responsive){
						font-size: 28px;	
					}
					span{
						display: inline-block;
						opacity: 0;
						transform: translateY(80px) rotateY(90deg);
                        @for $i from 1 through 20{
                            &:nth-of-type(#{$i}){
								@if ($i % 2 == 0) {
									@include transition(800ms,$delay:$i*40ms);
									transition-property: opacity, transform;
								}@else{
									@include transition(800ms,$delay:$i*80ms);
									transition-property: opacity, transform;
								}
                            }
                        }
					}
					&.is-active{
						opacity: 1;
						span{
							transform: translateY(0) rotateY(0deg);
							opacity: .88;
						}
					}
				}
				dt{
					margin: 16px 0 0;
					font-size: calc(15px + 10 * (100vw - 450px)/ 900);
					letter-spacing: .32em;
					opacity: 0;
					@include transition(1200ms);
					transition-property: opacity, transform;
					@media screen and (max-width:$responsive){
						padding: 0 30px;
						font-size: 15px;
					}
					&.is-active{
						opacity: .88;
					}
				}
			}
			.copy{
				height: 100%;
				position: absolute;
				top: 0;
				right: $margin;
				font-size: 10px;
				z-index: 2;
				line-height: 1;
				pointer-events: none;
				-webkit-writing-mode: vertical-rl;
				-moz-writing-mode: vertical-rl;
				-ms-writing-mode: tb-rl;
				writing-mode: vertical-rl;
				white-space: nowrap;
				letter-spacing: .2em;
				text-align: center;
				@media screen and (max-width:$responsive){
					right: 15px;
					font-size: 9px;
					letter-spacing: .24em;
				}
			}
			.progress-wrap{
				transform-origin: left top;
                transform: rotate(90deg) translateX(-50%);
				position: absolute;
				top: 50%;
				left: $margin;
				@include flex();
				@media screen and (max-width:$responsive){
					left: 30px;	
				}
				.txt{
					font-size: 10px;
					margin: 0 32px 0 0;
					letter-spacing: .48em;
					@media screen and (max-width:$responsive){
						font-size: 9px;
						margin: 0 24px 0 0;
					}
				}
			}
			#progress{
				$size:140px;
				width: $size;
				position: relative;
				#progressbar {
					$mg: 24px;
					content: '';
					width: calc(100% - #{$mg*2});
					height: 1px;
					background: #FFF;
					position: absolute;
					top: 50%;
					left: $mg;
					transform: translateY(-50%);
					&.active{
						&.first{
							&:before{
								animation: progress 4s ease;
							}
						}
						&:before{
							width: 100%;
							animation: progress 6s ease;
						}
						@keyframes progress{
							0%{
								width: 0;
								left: 0;
							}
							85%{
								width: 100%;
								left: 0;
							}
							100%{
								width: 0;
								left: 100%;
							}
						}
					}
					&:before{
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 0;
						height: 100%;
						background: $normal;
						@include transition(200ms);
					}
				}
				i{
					position: absolute;
					top: 50%;
					line-height: 1;
					transform: translateY(-50%);
					font-size: 10px;
					&.first-number{
						left: 0;
					}
					&.last-number{
						right: 0;
					}
				}
			}
		}
		.news{
			position: absolute;
			right: 0;
			bottom: 0;
			background: #000;
			color: #FFF;
			width: 640px;
			z-index: 101;
			padding: 32px;
			@include flex();
			align-items: center;
			justify-content: flex-start;
			font-size: 13.5px;
			line-height: 1.1;
			@media screen and (max-width:$responsive){
				width: 100%;
				font-size: 13px;
				padding: 20px;
			}
			&:before{
				content: '';
				width: 32px;
				height: 100%;
				transform: skewX(-20deg);
				position: absolute;
				top: 0;
				left: -16px;
				background: #000;
				@media screen and (max-width:$responsive){
					display: none;
				}
			}
			&:after{
				content: '';
				width: 8px;
				height: 8px;
				position: absolute;
				top: 8px;
				left: 4px;
				background: $blue;
				border-radius: 50%;
				@media screen and (max-width:$responsive){
					width: 7px;
					height: 7px;
					left: 6px;
					top: 6px;
				}
			}
			dd{
				width: 100px;
				border-right: rgba(#FFF,.24) 1px solid;
				@media screen and (max-width:$responsive){
					width: 88px;
				}
			}
			dt{
				width: calc(100% - 100px);
				padding: 0 0 0 32px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				@media screen and (max-width:$responsive){
					width: calc(100% - 88px);
					padding: 0 0 0 24px;
				}
			}
		}
	}
	.off{
		background: #FFF;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 200;
	}
}