.site-contact{
	.text{
		text-align: center;
		font-size: 15px;
		margin: 0 0 32px;
		@media screen and (max-width:$responsive){
			font-size: 13px;
			margin: 0 0 24px;
			text-align: left;
			br{
				display: none;
			}
		}
		a{
			text-decoration: underline;
		}
	}
    .send{
        text-align: center;
        margin: 48px 0 0;
		@media screen and (max-width:$responsive){
			margin: 32px 0 0;	
		}
        input{
            color: #FFF;
            background: $blue;
			border: $blue 1px solid;
            font-size: 20px;
            padding: 16px 96px;
            cursor: pointer;
			display: inline-block;
			border-radius: 999px;
			&:disabled{
				opacity: .12;
				cursor: auto;
			}
			@media screen and (max-width:$responsive){
				display: block;
				width: 100%;
				padding: 16px 0;
			}
        }
    }
	.privacy{
		margin: 48px 0 0;
		background: $glay;
		border-radius: 6px;
		height: 160px;
		padding: 24px;
		overflow: auto;
		dl{
			margin: 0 0 32px;
			&:last-of-type{
				margin: 0;
			}
			dt{
				font-weight: 700;
			}
		}
	}
}