@charset "utf-8";

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#080808;
$link:$normal;
$blue:#0cbbe7;
$dark: #003c8b;
$red: #e60012;
$border:#DDD;
$glay:#f5f5f6;


/* SITE SETTING
------------------------------------------------------------*/
$width:1300px;
$responsive: 768px;

$pc-head:80px;
$sp-head:60px;

$margin: 5%;
$margin-L: 8%;

@media screen and (min-width:$responsive + 1px){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:$responsive ){
	@include default(
		auto, //サイト幅
		12px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/firstview";
@import "page/index";
@import "page/company";
@import "page/works";
@import "page/contact";
@import "page/news";

#site-wrapper,#site-footer{
}

.site-module{
	&.wrap{
		padding: 80px $margin-L;
		@media screen and (max-width:1150px){
			padding: 80px $margin;
		}
		@media screen and (max-width:$responsive ){
			padding: 32px 15px;	
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
	}
	&.bg{
		background: $glay;
	}
	.flex-box{
		@include flex();
		&.left{
			justify-content: flex-start;
		}
		&.right{
			justify-content: flex-end;
		}
		&.top{
			align-items: flex-start;
		}
		&.bottom{
			align-items: flex-start;
		}
	}
	.inner{
		position: relative;
		top: 0;
		left: 0;
		margin: 0 8%;
		@media screen and (max-width:$responsive){
			margin: 0;	
		}
	}
	.bg-img{
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	h2.center{
		@include catch();
		text-align: center;
		font-size: 42px;
		font-weight: 800;
		margin: 0 0 40px;
		line-height: 1;
		letter-spacing: .08em;
		&:first-letter{
			color: $blue;
		}
		@media screen and (max-width:$responsive){
			font-size: 28px;	
			margin: 0 0 24px;
		}
		&.s{
			margin: 0 0 20px;
			@media screen and (max-width:$responsive){
				margin: 0 0 8px;	
			}
		}
		&:after{
			content: attr(data-text);
			text-align: center;
			display: block;
			font-size: 15px;
			margin: 12px 0 0;
			@media screen and (max-width:$responsive ){
				font-size: 13px;	
			}
		}
	}
	h2.borders,
	h3.borders{
		border-top: $border 1px solid;
		border-bottom: $border 1px solid;
		font-size: 20px;
		font-weight: 700;
		padding: 16px 8px;
		padding-left: 20px;
		margin-bottom: 24px;
		position: relative;
		@media screen and (max-width:$responsive){
			font-size: 18px;
			padding: 12px 4px;
			padding-left: 16px;
			margin-bottom: 0 0 20px;
		}
		&:before{
			content: '';
			width: 5px;
			border-radius: 6px;
			height: 70%;
			position: absolute;
			top: 15%;
			left: 0;
			background: $blue;
		}
	}
	.btnarea{
		margin: 40px 0 0;
		text-align: center;
		@media screen and (max-width:$responsive){
			margin: 24px 0 0;	
		}
		a{
			background: $normal;
			border: $normal 1px solid;
			font-size: 17px;
			display: inline-block;
			margin:0 8px;
			color: #FFF;
			padding: 10px 64px;
			@media screen and (max-width:$responsive){
				padding: 12px 0;
				display: block;
				margin: 0 0 12px;
				&:last-of-type{
					margin: 0;
				}
			}
			i{
				display: inline-block;
				margin: 0 0 0 8px;
			}
			&:hover{
				background: #FFF;
				color: $normal;
			}
			&.block{
				display: block;
				margin: 0;
			}
		}
	}
	.table-style{
		width: 100%;
		border: $border 1px solid;
		border-collapse: collapse;
		@media screen and (max-width:$responsive){
			display: block;
			tbody,
			tr,
			th,
			td{
				display: block;
			}
		}
		&.mb{
			margin: 0 0 64px;
			@media screen and (max-width:$responsive){
				margin: 0 0 24px;	
			}
		}
		tbody{
			tr{
				@media screen and (max-width:$responsive){
					border-bottom: $border 1px solid;
					&:last-child{
						border: none;
					}
				}
				th,td{
					padding: 15px;
					border: $border 1px solid;
					vertical-align: middle;
					text-align: left;
					@media screen and (max-width:$responsive){
						border: none;
					}
					input,
					select,
					option,
					textarea{
						@include font();
						border: $border 1px solid;
						padding: 8px 10px;
						width: 100%;
						font-size: 15px;
						border-radius: 4px;
						overflow: hidden;
						&:focus{
							box-shadow: inset 1px 2px 2px rgba(#000,.16);
						}
					}
					select{
						width: auto;
					}
					textarea{
						height: 200px;
					}
				}
				th{
					width: 220px;
					background: $glay;
					@media screen and (max-width:$responsive){
						width: auto;	
					}
					&.hiss{
						&:after{
							content: '必須';
							float: right;
							background: $blue;
							color: #FFF;
							padding: 2px 8px;
							border-radius: 4px;
							font-weight: 700;
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}

.scroll-anime{
	opacity: 0;
	transform: translateY(48px);
	@include transition(800ms);
	&._active{
		opacity: 1;
		transform: translateY(0);
	}
}

.scroll-img{
	position: relative;
	$ease-transition: cubic-bezier(0.86, 0, 0.07, 1);
	$speed: 600ms;
	&:before,
	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
	&:before{
		background: #FFF;
		@include transition($speed , $delay:$speed , $ease:$ease-transition);
	}
	&:after{
		background: #f2f2f2;
		@include transition($speed , $ease: $ease-transition);
	}
	&.__active{
		&:before{
			width: 0;
		}
		&:after{
			width: 0;
		}
	}
}

.scale-enter-active, .scale-leave-active {
	transition: 600ms;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
	transform: scale(1.1);
}

#loading{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: #FFF;
	@include flex();
	align-items: center;
	justify-content: center;
	font-size: 13px;
	overflow: hidden;
	opacity: 1;
	font-weight: 600;
	color: $dark;
	@include transition(500ms , $delay:1000ms);
	&.active{
		opacity: 0;
		#bar{
			width: 0 !important;
			@include transition(400ms , $delay:400ms);
		}
		#percent{
			animation: none;
			opacity: 0 !important;
			@include transition(300ms);
		}
	}
	.block{
		#percent{
			display: block;
			position: relative;
			z-index: 2;
			text-align: center;
			white-space: nowrap;
		}
		.svgload{
			width: 64px;
			height: 24px;
			background: url("../../images/common/loading.svg") no-repeat center;
			background-size: cover;
			margin: 0 auto 8px;
			animation: svganime .2s infinite alternate;
		}
	}
}


._move{
	$time: 1800ms;
    position: fixed;
    top: 0;
    right: 100%;
    width: 100%;
    height: 100%;
	z-index: 8000;
	@media screen and (min-width:$responsive + 1px){
          background: #FFF url("../../images/common/loading.svg") no-repeat center fixed;
          background-size: 64px;
	}
	@media screen and (max-width:$responsive){
		background: #FFF;
		&:before{
			content: '';
			width: 64px;
			height: 64px;
			position: absolute;
			top: 50%;
			left: 50%;
			background: url("../../images/common/loading.svg") no-repeat center;
			background-size: 64px;
			transform: translateX(-50%) translateY(-50%);
		}
	}
    &.moved{
		animation: anime $time cubic-bezier(0.645, 0.045, 0.355, 1);
		@keyframes anime{
			0%{
				right: 100%;
			}
			30%{
				right: 0;

			}
			70%{
				right: 0;

			}
			100%{
				right: -100%;
			}
		}
	}
}

