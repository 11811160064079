@charset "UTF-8";
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/* BASE COLOR
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
@media screen and (min-width: 769px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #080808;
    background: #FFF;
    font-size: 14px;
    line-height: 1.8;
    font-family: 'Montserrat',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em;
    overflow-x: hidden; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #080808; }
    a:hover {
      text-decoration: none;
      color: #222222; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after, #site-header:after, #site-header .gnavi > ul:after, .site-works .min-list:after, .site-news .sidebar ol.update li a:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 768px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #080808;
    background: #FFF;
    font-size: 12px;
    line-height: 1.8;
    font-family: 'Montserrat',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em;
    overflow-x: hidden; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #080808; }
    a:hover {
      text-decoration: none;
      color: #222222; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after, #site-header:after, #site-header .gnavi > ul:after, .site-works .min-list:after, .site-news .sidebar ol.update li a:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    max-width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

#site-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 28px 5%;
  width: 100%;
  -webkit-transition: all 400ms 0ms ease-in-out;
  -moz-transition: all 400ms 0ms ease-in-out;
  -ms-transition: all 400ms 0ms ease-in-out;
  -o-transition: all 400ms 0ms ease-in-out;
  transition: all 400ms 0ms ease-in-out; }
  @media screen and (max-width: 768px) {
    #site-header {
      position: absolute; } }
  #site-header a {
    color: #FFF;
    -webkit-transition: all 200ms 0ms ease-in-out;
    -moz-transition: all 200ms 0ms ease-in-out;
    -ms-transition: all 200ms 0ms ease-in-out;
    -o-transition: all 200ms 0ms ease-in-out;
    transition: all 200ms 0ms ease-in-out; }
    #site-header a:hover {
      opacity: .64; }
  @media screen and (min-width: 769px) {
    #site-header.active {
      background: #080808;
      padding: 16px 5%; }
      #site-header.active a {
        color: #FFF; } }
  #site-header .logo {
    width: 144px;
    float: left;
    background: url("../../../images/common/logo.png") no-repeat;
    background-size: cover; }
    @media screen and (max-width: 768px) {
      #site-header .logo {
        width: 96px; } }
    #site-header .logo img {
      width: 100%;
      opacity: 0;
      -webkit-transition: all 200ms 0ms ease-in-out;
      -moz-transition: all 200ms 0ms ease-in-out;
      -ms-transition: all 200ms 0ms ease-in-out;
      -o-transition: all 200ms 0ms ease-in-out;
      transition: all 200ms 0ms ease-in-out; }
  #site-header .gnavi {
    float: right;
    margin: 6px 0 0; }
    @media screen and (max-width: 768px) {
      #site-header .gnavi:before {
        content: '';
        float: none;
        position: fixed;
        top: 15px;
        right: 15px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #080808;
        border: #080808 1px solid;
        z-index: 1000;
        cursor: pointer;
        -webkit-transition: all 400ms 0ms ease-in-out;
        -moz-transition: all 400ms 0ms ease-in-out;
        -ms-transition: all 400ms 0ms ease-in-out;
        -o-transition: all 400ms 0ms ease-in-out;
        transition: all 400ms 0ms ease-in-out; }
      #site-header .gnavi:after {
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        content: "\f0c9";
        position: fixed;
        top: 24px;
        right: 29px;
        z-index: 1001;
        color: #FFF;
        font-size: 13px;
        cursor: pointer; }
      #site-header .gnavi.active:before {
        border: #FFF 1px solid;
        transform: scale(1.04); }
      #site-header .gnavi.active:after {
        content: "\f00d";
        right: 30px; } }
    @media screen and (min-width: 769px) {
      #site-header .gnavi > ul {
        display: block !important; } }
    @media screen and (max-width: 768px) {
      #site-header .gnavi > ul {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #080808;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 40% 0;
        z-index: 500; }
        #site-header .gnavi > ul:after {
          display: none; } }
    #site-header .gnavi > ul > li {
      list-style: none;
      float: left;
      margin: 0 5.5vw 0 0;
      font-size: 15px; }
      @media screen and (max-width: 768px) {
        #site-header .gnavi > ul > li {
          float: none;
          width: 100%;
          margin: 0; } }
      #site-header .gnavi > ul > li:last-child {
        margin: 0; }
      #site-header .gnavi > ul > li > a {
        font-weight: 600; }

#addmap iframe {
  filter: grayscale(100);
  vertical-align: bottom;
  height: 300px; }
  @media screen and (max-width: 768px) {
    #addmap iframe {
      height: 180px; } }

#site-footer {
  background: #080808;
  padding: 0 0 64px;
  color: #FFF; }
  @media screen and (max-width: 768px) {
    #site-footer {
      padding: 0 0 32px; } }
  #site-footer a {
    color: #FFF; }
  #site-footer .pagetop {
    background: rgba(255, 255, 255, 0.12);
    text-align: center;
    margin: 0 0 64px; }
    @media screen and (max-width: 768px) {
      #site-footer .pagetop {
        margin: 0 0 32px; } }
    #site-footer .pagetop a {
      display: block;
      padding: 12px 0; }
  #site-footer .wrap {
    padding: 0 8%; }
    @media screen and (max-width: 768px) {
      #site-footer .wrap {
        padding: 0 15px; } }
  #site-footer .fnav {
    text-align: center; }
    @media screen and (max-width: 768px) {
      #site-footer .fnav {
        border: rgba(255, 255, 255, 0.12) 1px solid;
        border-radius: 4px; } }
    #site-footer .fnav li {
      font-size: 11px;
      display: inline-block; }
      @media screen and (max-width: 768px) {
        #site-footer .fnav li {
          display: block;
          text-align: left;
          border-bottom: rgba(255, 255, 255, 0.12) 1px solid; }
          #site-footer .fnav li:last-child {
            border: none; }
          #site-footer .fnav li a {
            display: block;
            padding: 15px;
            position: relative; }
            #site-footer .fnav li a:after {
              font-weight: 900;
              font-family: "Font Awesome 5 Free";
              content: "\f054";
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
              opacity: .24; } }
      @media screen and (min-width: 769px) {
        #site-footer .fnav li:last-child:after {
          display: none; }
        #site-footer .fnav li:after {
          content: '|';
          display: inline-block;
          margin: 0 12px; } }
  #site-footer .copy {
    text-align: center;
    font-size: 10px;
    display: block;
    margin: 32px 0 0; }
    @media screen and (max-width: 768px) {
      #site-footer .copy {
        font-size: 8px; } }

#page-title {
  height: 360px;
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    #page-title {
      height: 180px;
      padding: 0;
      justify-content: center; } }
  #page-title:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(8, 8, 8, 0.32);
    z-index: 2; }
  #page-title .bg {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    -webkit-transition: all 1400ms 0ms linear;
    -moz-transition: all 1400ms 0ms linear;
    -ms-transition: all 1400ms 0ms linear;
    -o-transition: all 1400ms 0ms linear;
    transition: all 1400ms 0ms linear; }
  #page-title .box {
    color: rgba(255, 255, 255, 0.96);
    position: relative;
    z-index: 3;
    white-space: nowrap;
    text-align: center;
    line-height: 1;
    transform: translateY(-8px); }
    @media screen and (max-width: 768px) {
      #page-title .box {
        text-align: center;
        transform: translateY(-4px); } }
    #page-title .box #eng-title {
      content: attr(data-eng);
      font-size: 48px;
      font-weight: 700;
      display: block;
      margin: 0 0 8px;
      padding: 32px 0 0;
      letter-spacing: .08em; }
      @media screen and (max-width: 768px) {
        #page-title .box #eng-title {
          font-size: 24px;
          margin: 0; } }
    #page-title .box h1, #page-title .box p {
      font-size: 20px;
      font-weight: 700;
      transform: translateY(10px);
      -webkit-transition: all 800ms 600ms ease-in-out;
      -moz-transition: all 800ms 600ms ease-in-out;
      -ms-transition: all 800ms 600ms ease-in-out;
      -o-transition: all 800ms 600ms ease-in-out;
      transition: all 800ms 600ms ease-in-out; }
      @media screen and (max-width: 768px) {
        #page-title .box h1, #page-title .box p {
          font-size: 13px; } }

#bread {
  padding: 24px 8%;
  overflow: hidden;
  font-size: 13px;
  background: #f5f5f6; }
  @media screen and (max-width: 768px) {
    #bread {
      padding: 16px;
      font-size: 11px;
      width: 100%;
      overflow: hidden;
      position: relative; }
      #bread:after {
        content: '';
        width: 40px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0; }
      #bread ol {
        width: 200%; } }
  #bread a {
    color: #080808; }
    @media screen and (max-width: 768px) {
      #bread a {
        white-space: nowrap; } }
  #bread i {
    display: inline-block;
    margin: 0 12px 0 0;
    font-size: 11px;
    vertical-align: middle;
    transform: translateY(-1px);
    color: #080808; }
  #bread li {
    float: left;
    list-style: none; }
    #bread li:after {
      content: "\f054";
      font-family: "Font Awesome 5 Free";
      font-size: 8px;
      font-weight: 900;
      opacity: .4;
      display: inline-block;
      margin: 0 16px;
      transform: translateY(-2px); }
    #bread li:last-child:after {
      display: none; }

#site-firstview .flexer {
  width: 100vw;
  height: 100vh;
  min-height: 500px;
  overflow: hidden;
  position: relative;
  background: #000; }
  #site-firstview .flexer .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all 800ms 0ms ease-in-out;
    -moz-transition: all 800ms 0ms ease-in-out;
    -ms-transition: all 800ms 0ms ease-in-out;
    -o-transition: all 800ms 0ms ease-in-out;
    transition: all 800ms 0ms ease-in-out; }
    #site-firstview .flexer .swiper-container.swiper-container-initialized {
      opacity: 1; }
    #site-firstview .flexer .swiper-container .swiper-bg {
      width: 100vw;
      height: 100vh;
      background-size: cover;
      background-position: center; }
  #site-firstview .flexer .swiper-slide-active .swiper-bg,
  #site-firstview .flexer .swiper-slide-duplicate-active .swiper-bg,
  #site-firstview .flexer .swiper-slide-prev .swiper-bg {
    animation: zoomUp 6.5s linear 0s 1 normal both; }

@keyframes zoomUp {
  0% {
    transform: scale(1.08); }
  100% {
    transform: scale(1); } }
  #site-firstview .flexer .overbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    z-index: 10;
    color: #FFF;
    overflow: hidden;
    background: rgba(8, 8, 8, 0.16); }
    #site-firstview .flexer .overbox * {
      z-index: 2; }
    #site-firstview .flexer .overbox:before {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      pointer-events: none;
      box-shadow: inset 0 0 24vw rgba(0, 0, 0, 0.16);
      z-index: 1; }
    #site-firstview .flexer .overbox dl {
      text-align: center;
      font-weight: 700;
      line-height: 1.18;
      transform: translateY(-10%); }
      #site-firstview .flexer .overbox dl dd {
        font-family: 'Raleway', sans-serif;
        letter-spacing: .2em;
        font-size: calc(52px + 22 * (100vw - 450px)/ 900);
        transform: translateX(0.24em);
        opacity: 0;
        -webkit-transition: all 800ms 0ms ease-in-out;
        -moz-transition: all 800ms 0ms ease-in-out;
        -ms-transition: all 800ms 0ms ease-in-out;
        -o-transition: all 800ms 0ms ease-in-out;
        transition: all 800ms 0ms ease-in-out; }
        @media screen and (max-width: 768px) {
          #site-firstview .flexer .overbox dl dd {
            font-size: 28px; } }
        #site-firstview .flexer .overbox dl dd span {
          display: inline-block;
          opacity: 0;
          transform: translateY(80px) rotateY(90deg); }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(1) {
            -webkit-transition: all 800ms 80ms ease-in-out;
            -moz-transition: all 800ms 80ms ease-in-out;
            -ms-transition: all 800ms 80ms ease-in-out;
            -o-transition: all 800ms 80ms ease-in-out;
            transition: all 800ms 80ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(2) {
            -webkit-transition: all 800ms 80ms ease-in-out;
            -moz-transition: all 800ms 80ms ease-in-out;
            -ms-transition: all 800ms 80ms ease-in-out;
            -o-transition: all 800ms 80ms ease-in-out;
            transition: all 800ms 80ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(3) {
            -webkit-transition: all 800ms 240ms ease-in-out;
            -moz-transition: all 800ms 240ms ease-in-out;
            -ms-transition: all 800ms 240ms ease-in-out;
            -o-transition: all 800ms 240ms ease-in-out;
            transition: all 800ms 240ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(4) {
            -webkit-transition: all 800ms 160ms ease-in-out;
            -moz-transition: all 800ms 160ms ease-in-out;
            -ms-transition: all 800ms 160ms ease-in-out;
            -o-transition: all 800ms 160ms ease-in-out;
            transition: all 800ms 160ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(5) {
            -webkit-transition: all 800ms 400ms ease-in-out;
            -moz-transition: all 800ms 400ms ease-in-out;
            -ms-transition: all 800ms 400ms ease-in-out;
            -o-transition: all 800ms 400ms ease-in-out;
            transition: all 800ms 400ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(6) {
            -webkit-transition: all 800ms 240ms ease-in-out;
            -moz-transition: all 800ms 240ms ease-in-out;
            -ms-transition: all 800ms 240ms ease-in-out;
            -o-transition: all 800ms 240ms ease-in-out;
            transition: all 800ms 240ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(7) {
            -webkit-transition: all 800ms 560ms ease-in-out;
            -moz-transition: all 800ms 560ms ease-in-out;
            -ms-transition: all 800ms 560ms ease-in-out;
            -o-transition: all 800ms 560ms ease-in-out;
            transition: all 800ms 560ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(8) {
            -webkit-transition: all 800ms 320ms ease-in-out;
            -moz-transition: all 800ms 320ms ease-in-out;
            -ms-transition: all 800ms 320ms ease-in-out;
            -o-transition: all 800ms 320ms ease-in-out;
            transition: all 800ms 320ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(9) {
            -webkit-transition: all 800ms 720ms ease-in-out;
            -moz-transition: all 800ms 720ms ease-in-out;
            -ms-transition: all 800ms 720ms ease-in-out;
            -o-transition: all 800ms 720ms ease-in-out;
            transition: all 800ms 720ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(10) {
            -webkit-transition: all 800ms 400ms ease-in-out;
            -moz-transition: all 800ms 400ms ease-in-out;
            -ms-transition: all 800ms 400ms ease-in-out;
            -o-transition: all 800ms 400ms ease-in-out;
            transition: all 800ms 400ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(11) {
            -webkit-transition: all 800ms 880ms ease-in-out;
            -moz-transition: all 800ms 880ms ease-in-out;
            -ms-transition: all 800ms 880ms ease-in-out;
            -o-transition: all 800ms 880ms ease-in-out;
            transition: all 800ms 880ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(12) {
            -webkit-transition: all 800ms 480ms ease-in-out;
            -moz-transition: all 800ms 480ms ease-in-out;
            -ms-transition: all 800ms 480ms ease-in-out;
            -o-transition: all 800ms 480ms ease-in-out;
            transition: all 800ms 480ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(13) {
            -webkit-transition: all 800ms 1040ms ease-in-out;
            -moz-transition: all 800ms 1040ms ease-in-out;
            -ms-transition: all 800ms 1040ms ease-in-out;
            -o-transition: all 800ms 1040ms ease-in-out;
            transition: all 800ms 1040ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(14) {
            -webkit-transition: all 800ms 560ms ease-in-out;
            -moz-transition: all 800ms 560ms ease-in-out;
            -ms-transition: all 800ms 560ms ease-in-out;
            -o-transition: all 800ms 560ms ease-in-out;
            transition: all 800ms 560ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(15) {
            -webkit-transition: all 800ms 1200ms ease-in-out;
            -moz-transition: all 800ms 1200ms ease-in-out;
            -ms-transition: all 800ms 1200ms ease-in-out;
            -o-transition: all 800ms 1200ms ease-in-out;
            transition: all 800ms 1200ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(16) {
            -webkit-transition: all 800ms 640ms ease-in-out;
            -moz-transition: all 800ms 640ms ease-in-out;
            -ms-transition: all 800ms 640ms ease-in-out;
            -o-transition: all 800ms 640ms ease-in-out;
            transition: all 800ms 640ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(17) {
            -webkit-transition: all 800ms 1360ms ease-in-out;
            -moz-transition: all 800ms 1360ms ease-in-out;
            -ms-transition: all 800ms 1360ms ease-in-out;
            -o-transition: all 800ms 1360ms ease-in-out;
            transition: all 800ms 1360ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(18) {
            -webkit-transition: all 800ms 720ms ease-in-out;
            -moz-transition: all 800ms 720ms ease-in-out;
            -ms-transition: all 800ms 720ms ease-in-out;
            -o-transition: all 800ms 720ms ease-in-out;
            transition: all 800ms 720ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(19) {
            -webkit-transition: all 800ms 1520ms ease-in-out;
            -moz-transition: all 800ms 1520ms ease-in-out;
            -ms-transition: all 800ms 1520ms ease-in-out;
            -o-transition: all 800ms 1520ms ease-in-out;
            transition: all 800ms 1520ms ease-in-out;
            transition-property: opacity, transform; }
          #site-firstview .flexer .overbox dl dd span:nth-of-type(20) {
            -webkit-transition: all 800ms 800ms ease-in-out;
            -moz-transition: all 800ms 800ms ease-in-out;
            -ms-transition: all 800ms 800ms ease-in-out;
            -o-transition: all 800ms 800ms ease-in-out;
            transition: all 800ms 800ms ease-in-out;
            transition-property: opacity, transform; }
        #site-firstview .flexer .overbox dl dd.is-active {
          opacity: 1; }
          #site-firstview .flexer .overbox dl dd.is-active span {
            transform: translateY(0) rotateY(0deg);
            opacity: .88; }
      #site-firstview .flexer .overbox dl dt {
        margin: 16px 0 0;
        font-size: calc(15px + 10 * (100vw - 450px)/ 900);
        letter-spacing: .32em;
        opacity: 0;
        -webkit-transition: all 1200ms 0ms ease-in-out;
        -moz-transition: all 1200ms 0ms ease-in-out;
        -ms-transition: all 1200ms 0ms ease-in-out;
        -o-transition: all 1200ms 0ms ease-in-out;
        transition: all 1200ms 0ms ease-in-out;
        transition-property: opacity, transform; }
        @media screen and (max-width: 768px) {
          #site-firstview .flexer .overbox dl dt {
            padding: 0 30px;
            font-size: 15px; } }
        #site-firstview .flexer .overbox dl dt.is-active {
          opacity: .88; }
    #site-firstview .flexer .overbox .copy {
      height: 100%;
      position: absolute;
      top: 0;
      right: 5%;
      font-size: 10px;
      z-index: 2;
      line-height: 1;
      pointer-events: none;
      -webkit-writing-mode: vertical-rl;
      -moz-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      white-space: nowrap;
      letter-spacing: .2em;
      text-align: center; }
      @media screen and (max-width: 768px) {
        #site-firstview .flexer .overbox .copy {
          right: 15px;
          font-size: 9px;
          letter-spacing: .24em; } }
    #site-firstview .flexer .overbox .progress-wrap {
      transform-origin: left top;
      transform: rotate(90deg) translateX(-50%);
      position: absolute;
      top: 50%;
      left: 5%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      @media screen and (max-width: 768px) {
        #site-firstview .flexer .overbox .progress-wrap {
          left: 30px; } }
      #site-firstview .flexer .overbox .progress-wrap .txt {
        font-size: 10px;
        margin: 0 32px 0 0;
        letter-spacing: .48em; }
        @media screen and (max-width: 768px) {
          #site-firstview .flexer .overbox .progress-wrap .txt {
            font-size: 9px;
            margin: 0 24px 0 0; } }
    #site-firstview .flexer .overbox #progress {
      width: 140px;
      position: relative; }
      #site-firstview .flexer .overbox #progress #progressbar {
        content: '';
        width: calc(100% - 48px);
        height: 1px;
        background: #FFF;
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translateY(-50%); }
        #site-firstview .flexer .overbox #progress #progressbar.active.first:before {
          animation: progress 4s ease; }
        #site-firstview .flexer .overbox #progress #progressbar.active:before {
          width: 100%;
          animation: progress 6s ease; }

@keyframes progress {
  0% {
    width: 0;
    left: 0; }
  85% {
    width: 100%;
    left: 0; }
  100% {
    width: 0;
    left: 100%; } }
        #site-firstview .flexer .overbox #progress #progressbar:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background: #080808;
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out; }
      #site-firstview .flexer .overbox #progress i {
        position: absolute;
        top: 50%;
        line-height: 1;
        transform: translateY(-50%);
        font-size: 10px; }
        #site-firstview .flexer .overbox #progress i.first-number {
          left: 0; }
        #site-firstview .flexer .overbox #progress i.last-number {
          right: 0; }
  #site-firstview .flexer .news {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #000;
    color: #FFF;
    width: 640px;
    z-index: 101;
    padding: 32px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start;
    font-size: 13.5px;
    line-height: 1.1; }
    @media screen and (max-width: 768px) {
      #site-firstview .flexer .news {
        width: 100%;
        font-size: 13px;
        padding: 20px; } }
    #site-firstview .flexer .news:before {
      content: '';
      width: 32px;
      height: 100%;
      transform: skewX(-20deg);
      position: absolute;
      top: 0;
      left: -16px;
      background: #000; }
      @media screen and (max-width: 768px) {
        #site-firstview .flexer .news:before {
          display: none; } }
    #site-firstview .flexer .news:after {
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      top: 8px;
      left: 4px;
      background: #0cbbe7;
      border-radius: 50%; }
      @media screen and (max-width: 768px) {
        #site-firstview .flexer .news:after {
          width: 7px;
          height: 7px;
          left: 6px;
          top: 6px; } }
    #site-firstview .flexer .news dd {
      width: 100px;
      border-right: rgba(255, 255, 255, 0.24) 1px solid; }
      @media screen and (max-width: 768px) {
        #site-firstview .flexer .news dd {
          width: 88px; } }
    #site-firstview .flexer .news dt {
      width: calc(100% - 100px);
      padding: 0 0 0 32px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      @media screen and (max-width: 768px) {
        #site-firstview .flexer .news dt {
          width: calc(100% - 88px);
          padding: 0 0 0 24px; } }

#site-firstview .off {
  background: #FFF;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200; }

.site-index .context {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 48px; }
  @media screen and (max-width: 768px) {
    .site-index .context {
      font-size: 13px;
      margin: 0 0 24px; } }

.site-index .aboutlist {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .site-index .aboutlist {
      display: block; } }
  .site-index .aboutlist li {
    list-style: none;
    width: 30.5%; }
    @media screen and (max-width: 768px) {
      .site-index .aboutlist li {
        width: auto;
        margin: 0 0 24px; }
        .site-index .aboutlist li:last-child {
          margin: 0; } }
    .site-index .aboutlist li img {
      width: 100%; }
    .site-index .aboutlist li figure figcaption {
      margin: 16px 0 0;
      text-align: center; }

.site-index .workslist {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .site-index .workslist {
      display: block; } }
  .site-index .workslist._active li:nth-child(1) {
    opacity: 1;
    transform: translateY(0);
    -webkit-transition: all 400ms 80ms ease-in-out;
    -moz-transition: all 400ms 80ms ease-in-out;
    -ms-transition: all 400ms 80ms ease-in-out;
    -o-transition: all 400ms 80ms ease-in-out;
    transition: all 400ms 80ms ease-in-out; }
  .site-index .workslist._active li:nth-child(2) {
    opacity: 1;
    transform: translateY(0);
    -webkit-transition: all 400ms 160ms ease-in-out;
    -moz-transition: all 400ms 160ms ease-in-out;
    -ms-transition: all 400ms 160ms ease-in-out;
    -o-transition: all 400ms 160ms ease-in-out;
    transition: all 400ms 160ms ease-in-out; }
  .site-index .workslist._active li:nth-child(3) {
    opacity: 1;
    transform: translateY(0);
    -webkit-transition: all 400ms 240ms ease-in-out;
    -moz-transition: all 400ms 240ms ease-in-out;
    -ms-transition: all 400ms 240ms ease-in-out;
    -o-transition: all 400ms 240ms ease-in-out;
    transition: all 400ms 240ms ease-in-out; }
  .site-index .workslist._active li:nth-child(4) {
    opacity: 1;
    transform: translateY(0);
    -webkit-transition: all 400ms 320ms ease-in-out;
    -moz-transition: all 400ms 320ms ease-in-out;
    -ms-transition: all 400ms 320ms ease-in-out;
    -o-transition: all 400ms 320ms ease-in-out;
    transition: all 400ms 320ms ease-in-out; }
  .site-index .workslist li {
    width: 23%;
    position: relative;
    z-index: 2;
    background: #FFF;
    opacity: 0;
    transform: translateY(80px); }
    @media screen and (max-width: 768px) {
      .site-index .workslist li {
        width: auto;
        margin: 0 0 24px; }
        .site-index .workslist li:last-child {
          margin: 0; } }
    .site-index .workslist li a {
      display: block; }
    .site-index .workslist li figure {
      width: 64%;
      padding: 24px 0;
      margin: 0 auto; }
      @media screen and (max-width: 768px) {
        .site-index .workslist li figure {
          width: 120px; } }
    .site-index .workslist li img {
      width: 100%; }
    .site-index .workslist li dl {
      border-top: #DDD 1px solid;
      text-align: center;
      padding: 16px; }
      .site-index .workslist li dl dt {
        font-size: 14px;
        font-weight: 700; }
      .site-index .workslist li dl dd {
        color: #0cbbe7;
        font-size: 13px; }

.site-index .news {
  background: #080808;
  color: #FFF; }
  .site-index .news .display.flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .site-index .news .leftside {
    width: 24%; }
    @media screen and (max-width: 768px) {
      .site-index .news .leftside {
        width: auto; } }
    .site-index .news .leftside h3 {
      line-height: 1;
      font-size: 14px;
      margin: 0 0 16px;
      font-weight: 700; }
      @media screen and (max-width: 768px) {
        .site-index .news .leftside h3 {
          font-size: 12px; } }
      .site-index .news .leftside h3:before {
        content: attr(data-eng);
        font-size: 28px;
        display: inline-block;
        margin: 0 12px 0 0; }
        @media screen and (max-width: 768px) {
          .site-index .news .leftside h3:before {
            font-size: 20px; } }
    .site-index .news .leftside a {
      display: inline-block;
      font-weight: 700;
      color: #FFF;
      font-size: 15px; }
      @media screen and (max-width: 768px) {
        .site-index .news .leftside a {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 13px; } }
      .site-index .news .leftside a:hover {
        text-decoration: underline; }
      .site-index .news .leftside a i {
        display: inline-block;
        font-size: 13px;
        margin: 0 0 0 12px; }
  .site-index .news ol {
    width: 76%;
    height: 200px;
    padding: 0 40px 0 0;
    overflow: auto; }
    @media screen and (max-width: 768px) {
      .site-index .news ol {
        width: 100%;
        height: 164px;
        padding: 15px;
        border: rgba(255, 255, 255, 0.2) 1px solid;
        border-radius: 4px; } }
    .site-index .news ol li {
      border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
      padding: 0 0 24px;
      margin: 0 0 24px; }
      @media screen and (max-width: 768px) {
        .site-index .news ol li {
          margin: 0 0 15px;
          padding: 0 0 15px; } }
      .site-index .news ol li:last-child {
        margin: 0; }
      .site-index .news ol li a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        color: #FFF; }
        @media screen and (max-width: 768px) {
          .site-index .news ol li a {
            display: block; } }
        .site-index .news ol li a:hover h4 {
          text-decoration: underline; }
      .site-index .news ol li time, .site-index .news ol li .category {
        display: block;
        font-weight: 600; }
        @media screen and (max-width: 768px) {
          .site-index .news ol li time, .site-index .news ol li .category {
            float: left; } }
      .site-index .news ol li time {
        width: 14%;
        font-size: 12px;
        border: rgba(255, 255, 255, 0.2) 1px solid;
        color: #FFF;
        padding: 2px 0;
        text-align: center; }
        @media screen and (max-width: 768px) {
          .site-index .news ol li time {
            width: 80px;
            font-size: 10px;
            padding: 1px 12px;
            margin: 2px 0 0; } }
      .site-index .news ol li h4 {
        width: 86%;
        font-size: 16px;
        padding: 0 0 0 32px; }
        @media screen and (max-width: 768px) {
          .site-index .news ol li h4 {
            width: auto;
            font-size: 12px;
            padding: 8px 0 0;
            clear: both; } }

.site-works .workslist {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .site-works .workslist {
      display: block; } }
  .site-works .workslist li {
    width: calc(50% - 16px);
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px;
    margin-top: 32px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: relative;
    overflow: hidden; }
    @media screen and (max-width: 768px) {
      .site-works .workslist li {
        width: auto;
        display: block; }
        .site-works .workslist li:after {
          content: '';
          display: block;
          clear: both; } }
    @media screen and (min-width: 769px) {
      .site-works .workslist li:nth-child(1), .site-works .workslist li:nth-child(2) {
        margin-top: 0; } }
    .site-works .workslist li figure {
      width: 120px; }
      @media screen and (max-width: 768px) {
        .site-works .workslist li figure {
          width: 64px;
          float: left;
          margin: 0 20px 0 0; } }
      .site-works .workslist li figure img {
        width: 100%; }
    .site-works .workslist li dl {
      width: calc(100% - 120px);
      padding: 8px 0 0 32px; }
      @media screen and (max-width: 768px) {
        .site-works .workslist li dl {
          width: auto;
          padding: 10px 0 0; } }
      .site-works .workslist li dl dt {
        font-size: 17px;
        font-weight: 700; }
        @media screen and (max-width: 768px) {
          .site-works .workslist li dl dt {
            font-size: 15px; } }
      .site-works .workslist li dl dd.cate {
        font-weight: 700;
        color: #0cbbe7;
        font-size: 12px; }
      .site-works .workslist li dl dd.description {
        margin: 12px 0 0;
        padding: 12px 0 0;
        border-top: #DDD 1px solid;
        font-size: 12px; }
        @media screen and (max-width: 768px) {
          .site-works .workslist li dl dd.description {
            clear: both; } }
    .site-works .workslist li a.view {
      position: absolute;
      top: 0;
      right: 0;
      background: #080808;
      color: #FFF;
      font-size: 12px;
      padding: 3px 24px; }
      .site-works .workslist li a.view i {
        display: inline-block;
        margin: 0 0 0 8px;
        font-size: 10px;
        opacity: .64; }

.site-works h3.borders {
  margin-top: 80px; }
  @media screen and (max-width: 768px) {
    .site-works h3.borders {
      margin-top: 32px; } }

.site-works .min-list li {
  list-style: none;
  background: #FFF;
  border: #DDD 1px solid; }
  @media screen and (min-width: 769px) {
    .site-works .min-list li {
      list-style: none;
      width: 32%;
      float: left;
      margin: 0 2% 2% 0; }
      .site-works .min-list li:nth-child(3n+1) {
        clear: both; }
      .site-works .min-list li:nth-child(3n+3) {
        margin-right: 0; }
      .site-works .min-list li:nth-last-child(-n+3) {
        margin-bottom: 0; } }
  @media screen and (max-width: 768px) {
    .site-works .min-list li {
      margin: 0 0 16px; }
      .site-works .min-list li:last-child {
        margin: 0; } }
  .site-works .min-list li dl {
    padding: 16px; }
    .site-works .min-list li dl dt {
      font-size: 13px;
      font-weight: 700; }
    .site-works .min-list li dl dd {
      font-size: 12px; }
  .site-works .min-list li:nth-child(1), .site-works .min-list li:nth-child(2), .site-works .min-list li:nth-child(3) {
    margin-top: 0; }

.site-contact .text {
  text-align: center;
  font-size: 15px;
  margin: 0 0 32px; }
  @media screen and (max-width: 768px) {
    .site-contact .text {
      font-size: 13px;
      margin: 0 0 24px;
      text-align: left; }
      .site-contact .text br {
        display: none; } }
  .site-contact .text a {
    text-decoration: underline; }

.site-contact .send {
  text-align: center;
  margin: 48px 0 0; }
  @media screen and (max-width: 768px) {
    .site-contact .send {
      margin: 32px 0 0; } }
  .site-contact .send input {
    color: #FFF;
    background: #0cbbe7;
    border: #0cbbe7 1px solid;
    font-size: 20px;
    padding: 16px 96px;
    cursor: pointer;
    display: inline-block;
    border-radius: 999px; }
    .site-contact .send input:disabled {
      opacity: .12;
      cursor: auto; }
    @media screen and (max-width: 768px) {
      .site-contact .send input {
        display: block;
        width: 100%;
        padding: 16px 0; } }

.site-contact .privacy {
  margin: 48px 0 0;
  background: #f5f5f6;
  border-radius: 6px;
  height: 160px;
  padding: 24px;
  overflow: auto; }
  .site-contact .privacy dl {
    margin: 0 0 32px; }
    .site-contact .privacy dl:last-of-type {
      margin: 0; }
    .site-contact .privacy dl dt {
      font-weight: 700; }

.site-news .container {
  width: calc(100% - 280px);
  padding: 0 56px 0 0;
  float: left; }
  @media screen and (max-width: 768px) {
    .site-news .container {
      width: auto;
      float: none;
      padding: 0;
      margin: 0 0 32px; } }

.site-news .sidebar {
  width: 280px;
  float: right; }
  @media screen and (max-width: 768px) {
    .site-news .sidebar {
      width: auto;
      float: none; } }
  .site-news .sidebar ol.update li {
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: #DDD 1px solid; }
    .site-news .sidebar ol.update li:last-child {
      margin: 0; }
    .site-news .sidebar ol.update li a {
      display: block;
      color: #080808; }
    .site-news .sidebar ol.update li time {
      width: 96px;
      background: #0cbbe7;
      color: #FFF;
      padding: 2px 0;
      font-size: 12px;
      text-align: center;
      float: left; }
    .site-news .sidebar ol.update li h4 {
      width: calc(100% - 114px);
      float: right; }
  .site-news .sidebar .btnarea {
    margin: 24px 0 0; }

.site-news #pager {
  text-align: center;
  margin: 32px 0 0; }
  .site-news #pager a, .site-news #pager span {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    margin: 0 6px;
    border: #DDD 1px solid; }
  .site-news #pager span {
    border-color: #0cbbe7;
    background: #0cbbe7;
    color: #FFF; }

.site-news ol.listable {
  overflow: auto;
  border: #DDD 1px solid;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.06); }
  .site-news ol.listable li {
    border-bottom: #DDD 1px solid; }
    .site-news ol.listable li:last-child {
      border: none; }
    .site-news ol.listable li a {
      padding: 24px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      color: #080808; }
      @media screen and (max-width: 768px) {
        .site-news ol.listable li a {
          display: block;
          padding: 15px; } }
      .site-news ol.listable li a:hover {
        background: #f5f5f6; }
        .site-news ol.listable li a:hover h4 {
          text-decoration: underline; }
    .site-news ol.listable li time, .site-news ol.listable li .category {
      display: block;
      font-weight: 600; }
      @media screen and (max-width: 768px) {
        .site-news ol.listable li time, .site-news ol.listable li .category {
          float: left; } }
    .site-news ol.listable li time {
      width: 14%;
      font-size: 12px;
      background: #0cbbe7;
      color: #FFF;
      padding: 2px 0;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .site-news ol.listable li time {
          width: 80px;
          font-size: 10px;
          padding: 1px 12px;
          margin: 2px 0 0; } }
    .site-news ol.listable li h3 {
      width: 86%;
      font-size: 16px;
      padding: 0 0 0 32px; }
      @media screen and (max-width: 768px) {
        .site-news ol.listable li h3 {
          width: auto;
          font-size: 12px;
          padding: 8px 0 0;
          clear: both; } }

.site-news .detail {
  font-size: 15px;
  line-height: 2; }
  .site-news .detail a {
    color: #080808;
    text-decoration: underline; }
    .site-news .detail a:hover {
      color: #e60012; }
  .site-news .detail strong {
    font-weight: 700; }

.site-module.wrap {
  padding: 80px 8%; }
  @media screen and (max-width: 1150px) {
    .site-module.wrap {
      padding: 80px 5%; } }
  @media screen and (max-width: 768px) {
    .site-module.wrap {
      padding: 32px 15px; } }
  .site-module.wrap.no-top {
    padding-top: 0; }
  .site-module.wrap.no-bottom {
    padding-bottom: 0; }

.site-module.bg {
  background: #f5f5f6; }

.site-module .flex-box {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .site-module .flex-box.left {
    justify-content: flex-start; }
  .site-module .flex-box.right {
    justify-content: flex-end; }
  .site-module .flex-box.top {
    align-items: flex-start; }
  .site-module .flex-box.bottom {
    align-items: flex-start; }

.site-module .inner {
  position: relative;
  top: 0;
  left: 0;
  margin: 0 8%; }
  @media screen and (max-width: 768px) {
    .site-module .inner {
      margin: 0; } }

.site-module .bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.site-module h2.center {
  font-family: 'Raleway', sans-serif;
  text-align: center;
  font-size: 42px;
  font-weight: 800;
  margin: 0 0 40px;
  line-height: 1;
  letter-spacing: .08em; }
  .site-module h2.center:first-letter {
    color: #0cbbe7; }
  @media screen and (max-width: 768px) {
    .site-module h2.center {
      font-size: 28px;
      margin: 0 0 24px; } }
  .site-module h2.center.s {
    margin: 0 0 20px; }
    @media screen and (max-width: 768px) {
      .site-module h2.center.s {
        margin: 0 0 8px; } }
  .site-module h2.center:after {
    content: attr(data-text);
    text-align: center;
    display: block;
    font-size: 15px;
    margin: 12px 0 0; }
    @media screen and (max-width: 768px) {
      .site-module h2.center:after {
        font-size: 13px; } }

.site-module h2.borders,
.site-module h3.borders {
  border-top: #DDD 1px solid;
  border-bottom: #DDD 1px solid;
  font-size: 20px;
  font-weight: 700;
  padding: 16px 8px;
  padding-left: 20px;
  margin-bottom: 24px;
  position: relative; }
  @media screen and (max-width: 768px) {
    .site-module h2.borders,
    .site-module h3.borders {
      font-size: 18px;
      padding: 12px 4px;
      padding-left: 16px;
      margin-bottom: 0 0 20px; } }
  .site-module h2.borders:before,
  .site-module h3.borders:before {
    content: '';
    width: 5px;
    border-radius: 6px;
    height: 70%;
    position: absolute;
    top: 15%;
    left: 0;
    background: #0cbbe7; }

.site-module .btnarea {
  margin: 40px 0 0;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .site-module .btnarea {
      margin: 24px 0 0; } }
  .site-module .btnarea a {
    background: #080808;
    border: #080808 1px solid;
    font-size: 17px;
    display: inline-block;
    margin: 0 8px;
    color: #FFF;
    padding: 10px 64px; }
    @media screen and (max-width: 768px) {
      .site-module .btnarea a {
        padding: 12px 0;
        display: block;
        margin: 0 0 12px; }
        .site-module .btnarea a:last-of-type {
          margin: 0; } }
    .site-module .btnarea a i {
      display: inline-block;
      margin: 0 0 0 8px; }
    .site-module .btnarea a:hover {
      background: #FFF;
      color: #080808; }
    .site-module .btnarea a.block {
      display: block;
      margin: 0; }

.site-module .table-style {
  width: 100%;
  border: #DDD 1px solid;
  border-collapse: collapse; }
  @media screen and (max-width: 768px) {
    .site-module .table-style {
      display: block; }
      .site-module .table-style tbody,
      .site-module .table-style tr,
      .site-module .table-style th,
      .site-module .table-style td {
        display: block; } }
  .site-module .table-style.mb {
    margin: 0 0 64px; }
    @media screen and (max-width: 768px) {
      .site-module .table-style.mb {
        margin: 0 0 24px; } }
  @media screen and (max-width: 768px) {
    .site-module .table-style tbody tr {
      border-bottom: #DDD 1px solid; }
      .site-module .table-style tbody tr:last-child {
        border: none; } }
  .site-module .table-style tbody tr th, .site-module .table-style tbody tr td {
    padding: 15px;
    border: #DDD 1px solid;
    vertical-align: middle;
    text-align: left; }
    @media screen and (max-width: 768px) {
      .site-module .table-style tbody tr th, .site-module .table-style tbody tr td {
        border: none; } }
    .site-module .table-style tbody tr th input,
    .site-module .table-style tbody tr th select,
    .site-module .table-style tbody tr th option,
    .site-module .table-style tbody tr th textarea, .site-module .table-style tbody tr td input,
    .site-module .table-style tbody tr td select,
    .site-module .table-style tbody tr td option,
    .site-module .table-style tbody tr td textarea {
      font-family: 'Montserrat',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.024em;
      border: #DDD 1px solid;
      padding: 8px 10px;
      width: 100%;
      font-size: 15px;
      border-radius: 4px;
      overflow: hidden; }
      .site-module .table-style tbody tr th input:focus,
      .site-module .table-style tbody tr th select:focus,
      .site-module .table-style tbody tr th option:focus,
      .site-module .table-style tbody tr th textarea:focus, .site-module .table-style tbody tr td input:focus,
      .site-module .table-style tbody tr td select:focus,
      .site-module .table-style tbody tr td option:focus,
      .site-module .table-style tbody tr td textarea:focus {
        box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.16); }
    .site-module .table-style tbody tr th select, .site-module .table-style tbody tr td select {
      width: auto; }
    .site-module .table-style tbody tr th textarea, .site-module .table-style tbody tr td textarea {
      height: 200px; }
  .site-module .table-style tbody tr th {
    width: 220px;
    background: #f5f5f6; }
    @media screen and (max-width: 768px) {
      .site-module .table-style tbody tr th {
        width: auto; } }
    .site-module .table-style tbody tr th.hiss:after {
      content: '必須';
      float: right;
      background: #0cbbe7;
      color: #FFF;
      padding: 2px 8px;
      border-radius: 4px;
      font-weight: 700;
      font-size: 12px; }

.scroll-anime {
  opacity: 0;
  transform: translateY(48px);
  -webkit-transition: all 800ms 0ms ease-in-out;
  -moz-transition: all 800ms 0ms ease-in-out;
  -ms-transition: all 800ms 0ms ease-in-out;
  -o-transition: all 800ms 0ms ease-in-out;
  transition: all 800ms 0ms ease-in-out; }
  .scroll-anime._active {
    opacity: 1;
    transform: translateY(0); }

.scroll-img {
  position: relative; }
  .scroll-img:before, .scroll-img:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%; }
  .scroll-img:before {
    background: #FFF;
    -webkit-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -ms-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1); }
  .scroll-img:after {
    background: #f2f2f2;
    -webkit-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -ms-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1); }
  .scroll-img.__active:before {
    width: 0; }
  .scroll-img.__active:after {
    width: 0; }

.scale-enter-active, .scale-leave-active {
  transition: 600ms; }

.scale-enter, .scale-leave-to {
  opacity: 0;
  transform: scale(1.1); }

#loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #FFF;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  overflow: hidden;
  opacity: 1;
  font-weight: 600;
  color: #003c8b;
  -webkit-transition: all 500ms 1000ms ease-in-out;
  -moz-transition: all 500ms 1000ms ease-in-out;
  -ms-transition: all 500ms 1000ms ease-in-out;
  -o-transition: all 500ms 1000ms ease-in-out;
  transition: all 500ms 1000ms ease-in-out; }
  #loading.active {
    opacity: 0; }
    #loading.active #bar {
      width: 0 !important;
      -webkit-transition: all 400ms 400ms ease-in-out;
      -moz-transition: all 400ms 400ms ease-in-out;
      -ms-transition: all 400ms 400ms ease-in-out;
      -o-transition: all 400ms 400ms ease-in-out;
      transition: all 400ms 400ms ease-in-out; }
    #loading.active #percent {
      animation: none;
      opacity: 0 !important;
      -webkit-transition: all 300ms 0ms ease-in-out;
      -moz-transition: all 300ms 0ms ease-in-out;
      -ms-transition: all 300ms 0ms ease-in-out;
      -o-transition: all 300ms 0ms ease-in-out;
      transition: all 300ms 0ms ease-in-out; }
  #loading .block #percent {
    display: block;
    position: relative;
    z-index: 2;
    text-align: center;
    white-space: nowrap; }
  #loading .block .svgload {
    width: 64px;
    height: 24px;
    background: url("../../images/common/loading.svg") no-repeat center;
    background-size: cover;
    margin: 0 auto 8px;
    animation: svganime .2s infinite alternate; }

._move {
  position: fixed;
  top: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  z-index: 8000; }
  @media screen and (min-width: 769px) {
    ._move {
      background: #FFF url("../../images/common/loading.svg") no-repeat center fixed;
      background-size: 64px; } }
  @media screen and (max-width: 768px) {
    ._move {
      background: #FFF; }
      ._move:before {
        content: '';
        width: 64px;
        height: 64px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: url("../../images/common/loading.svg") no-repeat center;
        background-size: 64px;
        transform: translateX(-50%) translateY(-50%); } }
  ._move.moved {
    animation: anime 1800ms cubic-bezier(0.645, 0.045, 0.355, 1); }

@keyframes anime {
  0% {
    right: 100%; }
  30% {
    right: 0; }
  70% {
    right: 0; }
  100% {
    right: -100%; } }
