.site-index{
	.context{
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		margin: 0 0 48px;
		@media screen and (max-width:$responsive){
			font-size: 13px;
			margin: 0 0 24px;	
		}
	}
	.aboutlist{
		@include flex;
		@media screen and (max-width:$responsive){
			display: block;	
		}
		li{
			list-style: none;
			width: 30.5%;
			@media screen and (max-width:$responsive){
				width: auto;
				margin: 0 0 24px;
				&:last-child{
					margin: 0;
				}
			}
			img{
				width: 100%;
			}
			figure{
				figcaption{
					margin: 16px 0 0;
					text-align: center;
				}
			}
		}
	}
	.workslist{
		@include flex;
		@media screen and (max-width:$responsive){
			display: block;	
		}
		&._active{
			li{
				@for $i from 1 through 4{
					&:nth-child(#{$i}){
						opacity: 1;
						transform: translateY(0);
						@include transition(400ms,$delay:$i*80ms);
					}
				}
			}
		}
		li{
			width: 23%;
			position: relative;
			z-index: 2;
            background: #FFF;
            opacity: 0;
            transform: translateY(80px);
			@media screen and (max-width:$responsive){
				width: auto;
				margin: 0 0 24px;
				&:last-child{
					margin: 0;
				}
			}
			a{
				display: block;
			}
			figure{
				width: 64%;
				padding: 24px 0;
				margin: 0 auto;
				@media screen and (max-width:$responsive){
					width: 120px;	
				}
			}
			img{
				width: 100%;
			}
			dl{
				border-top: $border 1px solid;
				text-align: center;
				padding: 16px;
				dt{
					font-size: 14px;
					font-weight: 700;
				}
				dd{
					color: $blue;
					font-size: 13px;
				}
			}
		}
	}
	.news{
		background: $normal;
		color: #FFF;
		.display{
			&.flex{
				@include flex();
			}
		}
        .leftside{
            width: 24%;
			@media screen and (max-width:$responsive){
				width: auto;	
			}
			h3{
				line-height: 1;
				font-size: 14px;
				margin: 0 0 16px;
				font-weight: 700;
				@media screen and (max-width:$responsive){
					font-size: 12px;	
				}
				&:before{
					content: attr(data-eng);
					font-size: 28px;
					display: inline-block;
					margin: 0 12px 0 0;
					@media screen and (max-width:$responsive){
						font-size: 20px;	
					}
				}
			}
			a{
				display: inline-block;
				font-weight: 700;
				color: #FFF;
				font-size: 15px;
				@media screen and (max-width:$responsive){
					position: absolute;
					top: 0;
					right: 0;
					font-size: 13px;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					display: inline-block;
					font-size: 13px;
					margin: 0 0 0 12px;
				}
			}
        }
        ol{
            width: 76%;
            height: 200px;
            padding: 0 40px 0 0;
            overflow: auto;
			@media screen and (max-width:$responsive){
				width: 100%;
				height: 164px;
				padding: 15px;
				border: rgba(#FFF,.2) 1px solid;
				border-radius: 4px;
			}
            li{
                border-bottom: rgba(#FFF,.2) 1px solid;
                padding: 0 0 24px;
                margin: 0 0 24px;
				@media screen and (max-width:$responsive){
					margin: 0 0 15px;
					padding: 0 0 15px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: #FFF;;
					@media screen and (max-width:$responsive){
						display: block;
					}
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                time,.category{
                    display: block;
                    font-weight: 600;
					@media screen and (max-width:$responsive){
						float: left;
					}
                }
                time{
                    width: 14%;
                    font-size: 12px;
                    border: rgba(#FFF,.2) 1px solid;
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
					@media screen and (max-width:$responsive){
						width: 80px;
						font-size: 10px;
						padding: 1px 12px;
						margin: 2px 0 0;
					}
                }
                h4{
                    width: 86%;
                    font-size: 16px;
                    padding: 0 0 0 32px;
					@media screen and (max-width:$responsive){
						width: auto;
						font-size: 12px;
						padding: 8px 0 0;
						clear: both;
					}
                }
            }
        }
	}
}